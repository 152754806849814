// src/components/EcommerceNavbar.js
import React from 'react';
//import { Navbar, Nav, Form, FormControl, Button } from 'react-bootstrap';//
import 'bootstrap/dist/css/bootstrap.min.css';
import './Navbar.css';
import logo from '../assests/iqaalilogo/logo1.png'; // Ensure the path is correct

const EcommerceNavbar = () => {
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <a className="navbar-brand" href="/">
        <img src={logo} alt="IQaali Logo" className="d-inline-block align-top" />
        <span className="brand-name">IQaali</span>
      </a>
      <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarNav">
        <ul className="navbar-nav mr-auto">
          <li className="nav-item">
            <a className="nav-link" href="/">Home</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/shop">Shop</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/about">About Us</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/contact">Contact Us</a>
          </li>
        </ul>
        <div className="navbar-nav">
          <a className="nav-item nav-link" href="/cart">Cart / $0.00</a>
          <a className="nav-item nav-link" href="/login">Login</a>
        </div>
      </div>
    </nav>
  );
};

export default EcommerceNavbar;
