import React from 'react';
import { Carousel, Button } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './HeroSection.css'; // Import the CSS file

import image1 from '../assests/herosection/image1.jpg';
import image2 from '../assests/herosection/image2.jpg';
import image3 from '../assests/herosection/image3.jpg';

const HeroSection = () => {
  return (
    <Carousel controls={true} indicators={true}>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={image1}
          alt="First slide"
        />
        <Carousel.Caption>
          <h3>Browse Our Beauty & Personal Care</h3>
          <Button variant="primary">Shop Now</Button>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={image2}
          alt="Second slide"
        />
        <Carousel.Caption>
          <h3>Discover Our Clothing Shoes & Jewelry Collection</h3>
          <Button variant="primary">Shop Now</Button>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={image3}
          alt="Third slide"
        />
        <Carousel.Caption>
          <h3>Explore Our Kitchen & Dining Section</h3>
          <Button variant="primary">Shop Now</Button>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
}

export default HeroSection;