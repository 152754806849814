import React from 'react';
import './Footer.css';

// Import images from src folder
import logo from '../assests/iqaalilogo/logo1.png';
import visa from '../assests/footerlogo/icon1.svg';
import paypal from '../assests/footerlogo/icon2.svg';
import mastercard from '../assests/footerlogo/icon3.svg';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-top">
                <div className="footer-logo">
                    <img src={logo} alt="IQAALI Logo" />
                </div>
                <div className="footer-about">
                    <h3>IQAALI</h3>
                    <p>
                        Welcome to IQAALI, the leading premium product reseller. Our wide product inventory across numerous categories makes wholesale buying a lot easier. IQAALI works hard to meet the wholesale needs of retailers, resellers, and company owners.
                    </p>
                </div>
                <div className="footer-tags">
                    <h4>Tags</h4>
                    <ul>
                        <li><button className="link-button">Beauty & Personal Care</button></li>
                        <li><button className="link-button">Clothing</button></li>
                        <li><button className="link-button">Health & Household</button></li>
                        <li><button className="link-button">Kitchen & Dining</button></li>
                        <li><button className="link-button">Medical Supplies</button></li>
                        <li><button className="link-button">Shoes & Jewelry</button></li>
                        <li><button className="link-button">Vitamin & Mineral Supplements</button></li>
                    </ul>
                </div>
                <div className="footer-newsletter">
                    <h4>Subscribe to Our Newsletter</h4>
                    <p>
                        Stay updated with the latest news and trends in the online retail sector. Benefit from our tips to propel your business success. Subscribe now!
                    </p>
                    <div className="newsletter-input">
                        <input type="email" placeholder="Your Email (required)" />
                        <button type="button">Sign Up</button>
                    </div>
                </div>
            </div>
            <div className="footer-bottom">
                <p>Copyright 2024 © IQAALI</p>
                <div className="footer-payment-methods">
                    <img src={visa} alt="Visa" />
                    <img src={paypal} alt="PayPal" />
                    <img src={mastercard} alt="MasterCard" />
                </div>
            </div>
        </footer>
    );
};

export default Footer;
