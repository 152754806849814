// src/components/BrowseCategories.js
import React from 'react';
import './BrowseCategories.css';

import beautyImage from '../assests/category/i1.jpg';
import clothingImage from '../assests/category/i2.jpg';
import healthImage from '../assests/category/i3.jpg';
import kitchenImage from '../assests/category/i4.jpg';
import medicalImage from '../assests/category/i5.jpg';
import freeShippingIcon from '../assests/icons/icon1.png';
import customerServiceIcon from '../assests/icons/icon2.png';
import noCustomsIcon from '../assests/icons/icon3.png';

const BrowseCategories = () => {
  const categories = [
    { name: 'Beauty & Personal Care', products: 4, img: beautyImage },
    { name: 'Clothing, Shoes & Jewelry', products: 9, img: clothingImage },
    { name: 'Health & Household', products: 21, img: healthImage },
    { name: 'Kitchen & Dining', products: 26, img: kitchenImage },
    { name: 'Medical Supplies', products: 11, img: medicalImage },
  ];

  const infos = [
    { icon: freeShippingIcon, title: 'FREE SHIPPING ON ALL ORDERS', description: 'Get Free Shipping on all orders over $75 and free returns to our UK returns centre! Items are dispatched from the US and will arrive in 5-8 days.' },
    { icon: customerServiceIcon, title: 'AMAZING CUSTOMER SERVICE', description: 'Get Free Shipping on all orders over $75 and free returns to our UK returns centre! Items are dispatched from the US and will arrive in 5-8 days.' },
    { icon: noCustomsIcon, title: 'NO CUSTOMS OR DUTY FEES!', description: 'We pay these fees so you don’t have to! The total billed at checkout is the final amount you pay, inclusive of VAT, with no additional charges at the time of delivery!' },
  ];

  return (
    <div className="browse-categories">
      <h2>Browse Our Categories</h2>
      <div className="categories">
        {categories.map((category, index) => (
          <div className="category" key={index}>
            <img src={category.img} alt={category.name} />
            <div className="category-body">
              <h3 className="category-title">{category.name}</h3>
              <p className="category-text">{category.products} PRODUCTS</p>
            </div>
          </div>
        ))}
      </div>
      <div className="infos">
        {infos.map((info, index) => (
          <div className="info" key={index}>
            <img src={info.icon} alt={info.title} />
            <h3 className="info-title">{info.title}</h3>
            <p className="info-text">{info.description}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BrowseCategories;
