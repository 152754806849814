import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import './ProductSection.css';
import product1 from '../assests/productsection/Productimage1.jpg';
import product2 from '../assests/productsection/Productimage2.jpg';
import product3 from '../assests/productsection/Productimage3.jpg';
import product4 from '../assests/productsection/Productimage4.jpg';

const ProductSection = () => {
  const products = [
    {
      id: 1,
      title: 'CAROTE Pots and Pans Set Nonstick',
      description: '11Pcs Kitchen Cookware Sets, Stackable Induction Pot and Pan set for Cooking, Taupe Granite',
      price: '$79.89',
      image: product1,
    },
    {
      id: 2,
      title: 'Pots and Pans Set with Detachable Handle',
      description: '12 Pcs Nonstick Ceramic Cookware Set with Lids, Non Toxic Cookware Set, RV Cookware Set, Stackable Pots and Pans, PTFE & PFOA Free, Oven Safe, Space Saving - Navy',
      price: '$99.99',
      image: product2,
    },
    {
      id: 3,
      title: 'Hydro Flask Stainless Steel',
      description: 'Reusable Mug – Vacuum Insulated, BPA-Free, Non-Toxic, Closeable Press-In Lid slides shut to tame splashes and trap temps, then slides open wide enough for easy drinking and reusable straws',
      price: '$27.50',
      image: product3,
    },
    {
      id: 4,
      title: 'Rubbermaid Brilliance BPA Free Food Storage Containers',
      description: 'It contains Lids, Airtight, for Lunch, Meal Prep, and Leftovers, Set of 7',
      price: '$31.99',
      image: product4,
    },
  ];

  return (
    <Container className="product-section">
      <h2 className="text-center my-5">Featured Products</h2>
      <Row>
        {products.map((product) => (
          <Col key={product.id} md={3} sm={6} className="mb-4 d-flex">
            <Card className="h-100">
              <Card.Img variant="top" src={product.image} className="product-image" />
              <Card.Body className="d-flex flex-column">
                <Card.Title>{product.title}</Card.Title>
                <Card.Text className="flex-grow-1">{product.description}</Card.Text>
                <Card.Text><strong>{product.price}</strong></Card.Text>
                <Button variant="primary">Add to cart</Button>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
}

export default ProductSection;
