import React from 'react';
import EcommerceNavbar from './Components/EcommerceNavBar';
import HeroSection from './Components/HeroSection';
import ProductSection from './Components/ProductSection';
import MoreProducts from './Components/MoreProducts';
import BrowseCategories from './Components/BrowseCategories';
import Footer from './Components/Footer';


const App = () =>{
  return (
    <div>
      <EcommerceNavbar />
      <HeroSection />
      <ProductSection />
      <MoreProducts />
      <BrowseCategories />
      <Footer />
    </div>
  );
}

export default App;
