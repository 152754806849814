import React from 'react';
import { Card, Button, Row, Col, Container } from 'react-bootstrap';
import './ProductSection.css';

// Import images from src folder
import img1 from '../assests/moreproducts/ima1.jpg';
import img2 from '../assests/moreproducts/ima2.jpg';
import img3 from '../assests/moreproducts/ima3.jpg';
import img4 from '../assests/moreproducts/ima4.jpg';
import img5 from '../assests/moreproducts/ima5.jpg';
import img6 from '../assests/moreproducts/ima6.jpg';
import img7 from '../assests/moreproducts/ima7.jpg';
import img8 from '../assests/moreproducts/ima8.jpg';
import img9 from '../assests/moreproducts/ima9.jpg';
import img10 from '../assests/moreproducts/ima10.jpg';
import img11 from '../assests/moreproducts/ima11.jpg';
import img12 from '../assests/moreproducts/ima12.jpg';

const products = [
    { id: 1, title: "Visions 1.5 Liter Amber Glass Saucepan", price: "$27.95", image: img1 },
    { id: 2, title: "Vegetable Chopper, Pro Onion Chopper, Multifunctional", price: "$19.97", image: img2 },
    { id: 3, title: "T-fal Ingenio Nonstick Cookware Set", price: "$74.96", image: img3 },
    { id: 4, title: "Swiffer WetJet Hardwood and Floor Spray Mop", price: "$28.24", image: img4 },
    { id: 5, title: "Swiffer Sweeper Wet Mopping Cloth", price: "$13.76", image: img5 },
    { id: 6, title: "Swiffer Feather Dusters Multi-Surface Duster Refills", price: "$13.72", image: img6 },
    { id: 7, title: "Stardrops - The Pink Stuff - The Miracle All Purpose Cleaning Paste", price: "$5.00", image: img7 },
    { id: 8, title: "Stanley The Quik Flip Go Bottle", price: "$25.00", image: img8 },
    { id: 9, title: "Stanley Classic Trigger Action Travel Mug", price: "$22.00", image: img9 },
    { id: 10, title: "Scrubbing Bubbles Disinfectant Bathroom Grime Fighter Spray", price: "$4.17", image: img10 },
    { id: 11, title: "Kitchen Academy Induction Cookware Sets", price: "$109.99", image: img11 },
    { id: 12, title: "CAROTE Pots and Pans Set Nonstick", price: "$79.89", image: img12 }
];

const MoreProducts = () => {
    return (
        <Container className="product-section">
            <h2 className="section-title">More Products</h2>
            <Row>
                {products.map((product) => (
                    <Col sm={6} md={4} lg={3} key={product.id} className="product-card">
                        <Card>
                            <Card.Img variant="top" src={product.image} />
                            <Card.Body>
                                <Card.Title>{product.title}</Card.Title>
                                <Card.Text>{product.price}</Card.Text>
                                <Button variant="primary">Add to cart</Button>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
            </Row>
        </Container>
    );
}

export default MoreProducts;
